import {NgModule} from '@angular/core';
import { LayoutRoutingModule } from './layout-routing.module';
import {AdminComponent} from './components/admin/admin.component';
import {UserComponent} from './components/user/user.component';
import {SharedModule} from '../shared/shared.module';
import {AuthComponent} from './components/auth/auth.component';

@NgModule({
  declarations: [
    AdminComponent,
    UserComponent,
    AuthComponent
  ],
  imports: [
    LayoutRoutingModule,
    SharedModule
  ]
})
export class LayoutModule { }
