import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Logindata} from '../model/logindata.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {type} from 'os';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private storageName = {userdata: 'ipd-userdata', token: 'ipd-authToken'};

  private currentUser: BehaviorSubject<Logindata>  = new BehaviorSubject<Logindata>(null);
  private currentToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  getCurrentUser =  this.currentUser.asObservable();
  getCurrentToken = this.currentToken.asObservable();

  isVerified = false;
  isLoggedIn = true;
  currentUserData: any;
  currentUserRoles: any = [];

  get accessToken(): any {
    return this.currentToken.value;
  }

  isAdmin = false;
  hasAccessToAllPodcasts = false;
  accessibleTherapeuticTags = [];

  jwtHelper = new JwtHelperService();

  authenticateUrl = environment.authenticateUrl + window.location.protocol + '//' + window.location.host + '/auth';

  constructor(
    private http: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.getCurrentToken.subscribe(result => {
      this.setCurrentUser(result);
    });
  }

  async processToken(token: any): Promise<any> {
    try {
      const response: any = await this.http.post(environment.tokenVerifyUrl, {token}).toPromise();
      this.setCurrentToken(response.token);
      this.isVerified = true;
      return true;
    } catch (e) {
      // console.log(e);
      if (environment.enableAnalytics) {
        firebase.analytics().setUserId(null);
        firebase.analytics().setUserProperties({
          account_id: null,
          major_category: null,
          minor_category: null,
          subclass: null,
          email_domain: null
        });
      }
      if (e.status === 401) {
        sessionStorage.removeItem(this.storageName.token);
        window.location.href = this.authenticateUrl;
      }
      return false;
    }
  }

  setCurrentToken(token: any) {
    sessionStorage.setItem(this.storageName.token, JSON.stringify(token));
    this.currentToken.next(token);
  }

  setCurrentUser(token: any) {
    if (!this.jwtHelper.isTokenExpired(token)) {
      let userData: Logindata = this.jwtHelper.decodeToken(token);
      userData = this.processClaims(userData);
      this.processUserRoles(userData);
      // sessionStorage.setItem(this.storageName.userdata, JSON.stringify(userData));
      this.currentUser.next(userData);
      if (environment.enableAnalytics) {
        firebase.analytics().setUserId(userData[`${environment.claimUrl}/userId`]);
        const [ major_category, minor_category, subclass ] = userData[`${environment.claimUrl}/subscriberSegment`]?.split('|', 3) ?? []
        firebase.analytics().setUserProperties({
          account_id: userData.accountId,
          major_category,
          minor_category,
          subclass,
          email_domain: userData.email?.split('@')[1]
        });
      }
    }
  }

  async checkUser(calledFrom: string) {
    // dev work around if broken
    // this.setCurrentToken('token from https://secure.ipdanalytics.com/Admin/Api/Login')
    // return true;
    if (this.isVerified) {
      return true;
    } else {
      // console.log(calledFrom);
      if (sessionStorage.getItem(this.storageName.token)) {
        return await this.processToken(JSON.parse(sessionStorage.getItem(this.storageName.token)));
      } else {
        const returnUrl = window.location.pathname === '/auth' ? null : window.location.pathname
        window.location.href = this.authenticateUrl + (returnUrl ? window.encodeURIComponent(`?returnURL=${returnUrl}`) : '');
        return false;
      }
    }
  }

  processClaims(userData: Logindata) {
    const claims = ['accountId', 'subscriberPermission', 'therapeuticInsightsArea', 'userId'];
    for (const claim of claims) {
      const claimValue = userData[environment.claimUrl + claim]
      if (claimValue) {
        if (claim === 'therapeuticInsightsArea') {
          if (Array.isArray(claimValue)) {
            let therapueticAreas = [];
            claimValue.forEach((value) => {
              therapueticAreas = therapueticAreas.concat(value.split(' | '));
            });
            userData[claim] = therapueticAreas;
          } else {
            userData[claim] = claimValue.split(' | ');
          }
        } else if (claim === 'subscriberPermission') {
          userData[claim] = Array.isArray(claimValue) ? claimValue : [claimValue];
        } else {
          userData[claim] = claimValue;
        }
        delete userData[environment.claimUrl + claim];
      }
    }
    return userData;
  }

  processUserRoles(userData: Logindata) {
    if (userData.subscriberPermission.length > 0) {
      userData.subscriberPermission.forEach(role => {
        switch (role) {
          case 'Podcast_Admin':
            this.isAdmin = true;
            break;
          case 'Podcast':
            this.hasAccessToAllPodcasts = true;
            break;
          case 'TherapeuticInsights_Podcast':
            this.accessibleTherapeuticTags = userData.therapeuticInsightsArea;
            break;
        }
      });
      this.currentUserRoles = userData.subscriberPermission;
    }
  }
}
