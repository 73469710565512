import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './components/admin/admin.component';
import {UserComponent} from './components/user/user.component';
import {AuthGuard} from '../../guards/auth.guard';
import {AuthComponent} from './components/auth/auth.component';


const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: '',
    component: UserComponent, canActivate: [AuthGuard],
    children: [
      {path: '', loadChildren: () => import('../user/user.module').then((m) => m.UserModule)}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {
}
