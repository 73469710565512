// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndPoint: 'https://odd3q3xk7a.execute-api.us-east-1.amazonaws.com/dev/',
  authenticateUrl: 'https://beta-secure.ipdanalytics.com/User/Login?returnMethod=GET&ReturnApp=podcast&returnURL=',
  trackUrl: 'https://beta-secure.ipdanalytics.com/User/Activity/T',
  homeUrl: 'https://beta-secure.ipdanalytics.com/User/Home',
  claimUrl: 'http://secure.ipdanalytics.com/claims/',
  tokenVerifyUrl: 'https://login-dev.ipdanalytics.info/token',
  enableAnalytics: false,
  firebaseConfig: {}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
