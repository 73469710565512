import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityInterceptor implements HttpInterceptor {
  private authToken: string;
  constructor(private authService: AuthService, private router: Router) {
    this.authService.getCurrentToken.subscribe((token) => {
      this.authToken = token;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn) {
      const isApiUrl = request.url.startsWith(environment.apiEndPoint);
      if (isApiUrl) {
        request = request.clone({
          setHeaders: {
            Authorization: `${this.authToken}`
          }
        });
      }
    }
    return next.handle(request);
  }
}
