  <header>
  <div class="row">
    <div class="col-sm-4">
      <img src="/assets/images/podcast-logo.png" class="img-fluid" style="height: 60px !important;" />
    </div>
    <div class="col-sm-8">
      <div class="w-100 h-100 d-inline-block wave-image"></div>
    </div>
  </div>
</header>

<div class="row">
  <div class="col-sm-12 mr-0 py-2">
    <ul class="main-nav">
      <li><a routerLink="/home"><img src="/assets/images/home-1.png" class="home-icon-p" width="30px" />Podcast Home</a></li>
      <li><a routerLink="/all"><span><i class="fas fa-play-circle" aria-hidden="true"></i></span>All Episodes</a></li>
      <li><a routerLink="/basic"><span><i class="fas fa-graduation-cap" aria-hidden="true"></i></span>Back to Basics Episodes</a></li>
      <li><a href="https://secure.ipdanalytics.com/User/Pharma/RxStrategy" target="_blank" rel="noopener noreferrer"><img src="/assets/images/payer.png" width="28px" />Payer & Provider Insights Home</a></li>
      <li *ngIf="isAdmin"><a routerLink="/admin"><span><i class="fas fa-user-cog"></i></span>Admin</a></li>
    </ul>
  </div>
</div>
<div class="row heading1 py-3">
  <div class="col-sm-5 mb-1">
    <img src="/assets/images/podcast_01.png" class="img-fluid" height="50px"/>
  </div>
  <div class="col-sm-7">
    <p>Download IPD's App for <a href="https://play.google.com/store/apps/details?id=com.ipdanalytics.app"  rel="noopener noreferrer" target="_blank">Android</a> or <a href="https://apps.apple.com/us/app/ipd-analytics/id1488612766"  rel="noopener noreferrer" target="_blank">Apple</a> to access podcast episodes and transcripts! <br />
      For login information, look for an email in your inbox or contact your account manager.</p>
  </div>
</div>
