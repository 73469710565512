<div class="player">
  <div class="row heading2">
    <div class="col-sm-9">
      <h4 class="text-ellipsis" style="padding-top: 8px;">
        <span *ngIf="currentPodcast && state.playing">
          <svg xmlns="http://www.w3.org/2000/svg" class="equilizer" viewBox="0 0 128 128">
            <g>
              <title>Audio Equilizer</title>
              <rect class="bar" transform="translate(0,0)" y="15"></rect>
              <rect class="bar" transform="translate(25,0)" y="15"></rect>
              <rect class="bar" transform="translate(50,0)" y="15"></rect>
              <rect class="bar" transform="translate(75,0)" y="15"></rect>
              <rect class="bar" transform="translate(100,0)" y="15"></rect>
            </g>
          </svg>
        </span>
        {{(currentPodcast)?currentPodcast.title:'Please select a podcast'}}
      </h4>

    </div>
    <div class="col-sm-3 text-right">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="autoplay" [(ngModel)]="autoPlay" [checked]="autoPlay" />
        <label class="custom-control-label pt-1" for="autoplay">Autoplay Next</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="progress-section">
        <input type="range" class="e-range" min="0" [max]="state.duration" step="1" id="customRange2" [(ngModel)]="state.currentTime" (ngModelChange)="changePercent()" [disabled]="!currentPodcast" [ngClass]="{'disabled': !currentPodcast}" />
      </div>
      <div class="row mt-2">
        <div class="col-sm-6">
          {{(currentPodcast && state.readableCurrentTime)?state.readableCurrentTime:'00:00:00'}}
        </div>
        <div class="col-sm-6 text-right">
          {{(currentPodcast && state.readableDuration)?state.readableDuration:'00:00:00'}}
        </div>
      </div>
    </div>
  </div>
  <div class="row footer">
    <div class="col-sm-3 pt-1">
      <div class="dropdown" ngbDropdown>
        <a class="dropdown-toggle clickable-tag" id="speed-menu" ngbDropdownToggle>
          <span class="mr-1"><i class="fa fa-tachometer-alt fa-lg col-pri" aria-hidden="true"></i></span>Speed {{playSpeed.display}}
        </a>
        <ul class="dropdown-menu" role="menu" aria-labelledby="speed-menu" ngbDropdownMenu>
          <li role="presentation" class="mb-2"><span role="menuitem" tabindex="-1"><b>Choose Speed:</b></span></li>
          <li role="presentation" class="mb-1" *ngFor="let speed of availablePlaySpeeds; index as i">
            <div class="form-check" role="menuitem" tabindex="-1">
              <input class="form-check-input" type="checkbox" name="currentPlaySpeed" [value]="speed.value" [checked]="checkPlaySpeed(speed)" [id]="'playspeed-'+i" (click)="changePlaySpeed(speed)" />
              <label class="form-check-label" [for]="'playspeed-'+i">{{speed.display}}</label>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-6 text-center controls">
      <a class="clickable-tag" (click)="next()" [ngClass]="{'disabled': (podcastPlaylist.length === currentFile.podcastIndex || podcastPlaylist.length === 0 || podcastPlaylist.length === 1)}"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
      <a class="clickable-tag" (click)="seekTo(-30)" [ngClass]="{'disabled': !currentPodcast}"><span>30</span><i class="fa fa-undo" aria-hidden="true"></i></a>
      <a class="clickable-tag" (click)="play()" *ngIf="!state.playing" [ngClass]="{'disabled': !currentPodcast}"><i class="fa fa-play-circle" aria-hidden="true"></i></a>
      <a class="clickable-tag" (click)="pause()" *ngIf="state.playing" [ngClass]="{'disabled': !currentPodcast}"><i class="fa fa-pause-circle" aria-hidden="true"></i></a>
      <a class="clickable-tag" (click)="seekTo(30)" [ngClass]="{'disabled': !currentPodcast}"><span>30</span><i class="fa fa-redo" aria-hidden="true"></i></a>
      <a class="clickable-tag" (click)="previous()" [ngClass]="{'disabled': (podcastPlaylist.length === 1 || podcastPlaylist.length === 0 || currentFile.podcastIndex === 1)}"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
    </div>
    <div class="col-sm-3 text-right volume pt-1">
      <input type="range" class="form-control-range clickable-tag" min="0" max="100" step="1" id="customRange3" [(ngModel)]="volume" (ngModelChange)="changeVolume()" />
      <span *ngIf="!mute && volume>0" (click)="muteVolume()" class="clickable-tag"><i class="fa fa-volume-up" aria-hidden="true"></i></span>
      <span *ngIf="mute || volume===0" (click)="muteVolume()" class="clickable-tag"><i class="fa fa-volume-mute" aria-hidden="true"></i></span>
    </div>
  </div>
</div>
