import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PodcastMetadata} from '../model/podcastMetadata.model';
import {throwError} from 'rxjs';
import {ExtendedHttpClient} from './extended-http-client';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private apiEndPoint = environment.apiEndPoint;

  constructor(private http: ExtendedHttpClient) {
  }

  replaceAll(target: string, search: string, replacement: string) {
    return target.replace(new RegExp(search, 'g'), replacement);
  }

  async listFiles(page: number = 0, search: string = '', status: string = 'published', isAdmin: boolean = false, pageSize: number = 20): Promise<Array<any>> {
    try {
      let queryParams = '';
      queryParams = '?page=' + page;
      queryParams = queryParams + '&pageSize=' + pageSize;
      queryParams = queryParams + '&status=' + status;
      if (search) {
        if (!queryParams) {
          queryParams = '?';
        } else {
          queryParams = queryParams + '&';
        }
        queryParams = queryParams + 'search=' + search;
      }
      if (isAdmin) {
        queryParams = queryParams + '&isAdmin=1';
      }
      const response: any = await this.http.get('s3list' + queryParams);
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async getSignedUrl(filePrefix: string, fileName: string, post: boolean = false, therapeuticAreaTags: string = '') {
    try {
      const data: any = {filePrefix: this.replaceAll(filePrefix, ' ', '-'), fileName: this.replaceAll(fileName, ' ', '-')};
      if (therapeuticAreaTags !== '') {
        data.therapeuticAreaTags = therapeuticAreaTags;
      }
      const response: any = await this.http.post(((post) ? 's3getpostsignedurl' : 's3getsignedurl'), data);
      return response.data;
    } catch (e) {
      console.log(e);
      if (e.status === 403) {
        return false;
      }
    }
  }

  async postMetadata(metadata: PodcastMetadata) {
    try {
      if (metadata.filePrefix) {
        metadata.filePrefix = this.replaceAll(metadata.filePrefix, ' ', '-');
      }
      if (metadata.fileName) {
        metadata.fileName = this.replaceAll(metadata.fileName, ' ', '-');
      }
      if (metadata.transcriptFileName) {
        metadata.transcriptFileName = this.replaceAll(metadata.transcriptFileName, ' ', '-');
      }
      if (metadata.imageFileName) {
        metadata.imageFileName = this.replaceAll(metadata.imageFileName, ' ', '-');
      }
      const response: any = await this.http.post('s3postmetadata', metadata);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async uploadFile(filePrefix: string, file: File){
    try {
      const postData = await this.getSignedUrl(filePrefix, file.name, true);
      const formData = new FormData();
      Object.entries(postData.fields).forEach(([k, v]) => {
        // @ts-ignore
        formData.append(k, v);
      });
      formData.append('file', file);
      const response: any = await this.http.post(postData.url, formData);
      return true;
    } catch (e) {
      console.log(e);
      throwError(e);
    }
  }

  async deletePodcast(filePrefix: string, fileName: string, id: string) {
    try {
      const data = {filePrefix: this.replaceAll(filePrefix, ' ', '-'), fileName: this.replaceAll(fileName, ' ', '-'), id};
      const response: any = await this.http.post('deleteobject', data);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async deleteFile(fileName: string, fileType: string, metadata: PodcastMetadata) {
    try {
      const data = {fileName: this.replaceAll(fileName, ' ', '-'), fileType, metadata};
      return await this.http.post('deletefile', data);
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  // tslint:disable-next-line:ban-types
  async getMetadata(filePrefix: string) {
    const data = {filePrefix: this.replaceAll(filePrefix, ' ', '-')};
    try {
      return await this.http.post('fetchpodcastbyid', data);
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async checkPodcastExists(filePrefix: string) {
    const data = {filePrefix: this.replaceAll(filePrefix, ' ', '-')};
    try {
      const response: any = await this.http.post('checkpodcastexists', data);
      return response.exists;
    } catch (e) {
      console.log(e);
    }
  }

  async reindexFiles() {
    try {
      const response: any =  await this.http.post('reindex', null);
      return response;
    } catch (e) {
      console.log(e);
    }
  }

}
