import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PodcastMetadata} from '../../../../model/podcastMetadata.model';
import {StreamState} from '../../../../interface/stream-state';
import {AudioService} from '../../../../services/audio.service';
import {FileService} from '../../../../services/file.service';
import {TemporaryService} from '../../../../services/temporary.service';
import {FormControl} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {Logindata} from '../../../../model/logindata.model';
import has = Reflect.has;
import {ModalService} from '../../../../services/modal.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EpisodeService} from '../../../../services/episode.service';
import {catchError} from 'rxjs/operators';
import * as firebase from 'firebase';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnDestroy {

  currentPodcast: PodcastMetadata;
  podcastPlaylist: Array<PodcastMetadata> = [];
  state: StreamState;

  currentFile: any = {podcastIndex: 0, podcast: {}};
  volume = 100;
  autoPlay = false;
  mute = false;
  originalVolume = 100;

  availablePlaySpeeds: any = [
    {value: 1.0, display: '1x (Normal)'},
    {value: 1.25, display: '1.25x'},
    {value: 1.5, display: '1.5x'}
  ];

  playSpeed: any = this.availablePlaySpeeds[0];
  currentPlaySpeed = new FormControl('');

  userData: Logindata;
  modalReference: NgbModalRef;

  loadingAudio = false;
  loadQueue = null;

  private subscription: any = {playList: {}, selectedPodcast: {}, audioService: {}};

  @ViewChild('alertmodal') alertmodal: ElementRef;

  constructor(
    private audioService: AudioService,
    private temporaryService: TemporaryService,
    private fileService: FileService,
    private authService: AuthService,
    private modalService: ModalService,
    private episodeService: EpisodeService
  ) {
    this.authService.getCurrentUser.subscribe(data => {
      this.userData = data;
    });
  }

  async ngOnInit() {
    this.state = new StreamState();
    this.subscription.playList = this.temporaryService.getPodcastPlayList.subscribe(result => {
      if (result) {
        this.podcastPlaylist = result;
      }
    });
    this.subscription.selectedPodcast = this.temporaryService.getSelectedPodcastIndex.subscribe(result => {
      if (result) {
        this.playSpeed = this.availablePlaySpeeds[0];
        this.openFile(result);
      }
    });
    this.subscription.audioService = this.audioService.getState().subscribe(event => {
      this.state = event;
      if (this.state.playing && this.loadingAudio) {
        this.loadingAudio = false;
        if (this.loadQueue !== null) {
          this.openFile(this.loadQueue);
          this.loadQueue = null;
        }
      }
      if (this.state.error && this.loadingAudio){
        this.loadingAudio = false;
        this.state.playing = false;
        this.state.completePercent = 0;
        if (this.loadQueue !== null) {
          this.openFile(this.loadQueue);
          this.loadQueue = null;
        }
      }
      if (this.state.completed) {
        if (environment.enableAnalytics) {
          firebase.analytics().logEvent('audio_complete', {audio_title: this.currentPodcast.title});
        }
      }
      if (this.state.completed && this.autoPlay) {
        this.next();
      }
    });
  }

  async playStream(podcast: PodcastMetadata) {
    const hasAccess = await this.episodeService.hasAccess(podcast, false, true);
    if (hasAccess) {
      if (environment.enableAnalytics) {
        firebase.analytics().logEvent('audio_start', {audio_title: podcast.title});
        fetch(
          `${environment.trackUrl}?type=View&module=RxStrategy&detail=Podcast&Page=${encodeURIComponent('RxStrategyPodcastEpisode/Download')}&Product=${encodeURIComponent(podcast.title)}`,
          { credentials: 'include', mode: 'no-cors' }
        );
      }
      this.loadingAudio = true;
      const url = await this.fileService.getSignedUrl(podcast.filePrefix, podcast.fileName, false, podcast.therapeuticAreaTags);
      if (url) {
        this.audioService.playStream(url, this.volume, this.playSpeed.value).subscribe(events => {
            // for playing the audio file subscribe is needed
        });
      } else {
        this.temporaryService.setAlertData('You do not have access to ' + podcast.title);
      }
    } else {
      this.currentPodcast = null;
      this.state.playing = false;
      this.state.completePercent = 0;
    }
  }

  openFile(podcastIndex) {
    if (this.currentFile.podcastIndex === podcastIndex && this.currentFile.podcast.fileName === this.podcastPlaylist[podcastIndex - 1].fileName){
      return;
    }
    if (this.loadingAudio){
      this.loadQueue = podcastIndex;
      return;
    }
    const podcast = this.podcastPlaylist[podcastIndex - 1];
    this.currentFile = { podcastIndex, podcast };
    this.currentPodcast = podcast;
    this.audioService.stop();
    this.playSpeed = this.availablePlaySpeeds[0];
    this.playStream(podcast);
  }

  pause() {
    this.audioService.pause();
  }

  play() {
    this.audioService.play();
  }

  stop() {
    this.audioService.stop();
  }

  async next() {
    if (this.podcastPlaylist.length === this.currentFile.podcastIndex){
      return;
    }
    const index = this.currentFile.podcastIndex + 1;
    await this.openFile(index);
  }

  async previous() {
    if (this.podcastPlaylist.length === 1 || this.podcastPlaylist.length === 0 || this.currentFile.podcastIndex === 1){
      return;
    }
    const index = this.currentFile.podcastIndex - 1;
    await this.openFile(index);
  }

  seekTo(value) {
    this.audioService.seekTo(this.state.currentTime + value);
  }

  changeVolume() {
    this.audioService.changeVolume(this.volume);
    if (this.volume > 0) {
      this.mute = false;
    } else {
      this.mute = true;
    }
  }

  changePlaySpeed(value) {
    if (this.playSpeed.value === value.value) {
      this.playSpeed = this.availablePlaySpeeds[0];
      this.audioService.changePlaySpeed(this.playSpeed.value);
      return;
    }
    this.playSpeed = value;
    this.audioService.changePlaySpeed(this.playSpeed.value);
  }

  checkPlaySpeed(speedRecord: any) {
    if (this.playSpeed.value === speedRecord.value) {
      return true;
    } else {
      return false;
    }
  }

  closeModal(message) {
    this.modalReference.close(message);
  }

  changePercent() {
    if (!this.currentPodcast){
      this.state.completePercent = 0;
      this.state.currentTime = 0;
      return;
    }
    // this.audioService.seekTo(this.state.duration * (this.state.completePercent / 100));
    this.audioService.seekTo(this.state.currentTime);
  }

  muteVolume() {
    if (this.mute) {
      this.mute = false;
      this.volume = this.originalVolume;
      this.audioService.changeVolume(this.volume);
    } else {
      this.mute = true;
      this.originalVolume = this.volume;
      this.volume = 0;
      this.audioService.changeVolume(0);
    }
  }

  ngOnDestroy() {
    this.subscription.playList.unsubscribe();
    this.subscription.selectedPodcast.unsubscribe();
    this.subscription.audioService.unsubscribe();
    this.temporaryService.setPodcastPlayList(null);
    this.audioService.stop();
    this.state = new StreamState();
    this.state.completePercent = 0;
    this.podcastPlaylist = [];
    this.currentPodcast = null;
    this.currentFile = {podcastIndex: 0, podcast: {}};
    // console.log('Called destroy');
  }

}
