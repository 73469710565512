import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as firebase from 'firebase';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'angular-app';

  constructor() {
    if (environment.enableAnalytics) {
      firebase.initializeApp(environment.firebaseConfig);
      firebase.analytics();
    }
  }

  async ngOnInit() {
  }

}
