import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PodcastMetadata} from '../model/podcastMetadata.model';
import {environment} from '../../environments/environment';
import {FileService} from './file.service';
import {ExtendedHttpClient} from './extended-http-client';
import {TemporaryService} from './temporary.service';

@Injectable({
  providedIn: 'root'
})
export class EpisodeService {

  constructor(
    private http: ExtendedHttpClient,
    private fileService: FileService,
    private tempService: TemporaryService
  ) { }

  async listEpisodes(fetchType: string = 'all', page: number = 0, search: string = '', therapeuticAreaTags: string = '') {
    let queryParams = '?status=published';
    if (fetchType !== 'all') {
      queryParams += '&type=' + fetchType;
    }
    if (therapeuticAreaTags && therapeuticAreaTags !== ''){
      queryParams += '&therapeuticAreaTags=' + encodeURI(therapeuticAreaTags);
      // queryParams += '&pageSize=21';
    }
    if (page && page > 0) {
      queryParams += '&page=' + page;
    }
    if (search) {
      queryParams += '&search=' + encodeURI(search);
    }
    try {
      const response: any = await this.http.get('s3list' + queryParams);
      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async hasAccess(podcast: PodcastMetadata, redirect: boolean = false, showAlert: boolean = false) {
    try {
      const data: any = {filePrefix: this.fileService.replaceAll(podcast.filePrefix, ' ', '-'), fileName: this.fileService.replaceAll(podcast.fileName, ' ', '-')};
      const response: any = await this.http.post('checkAccess', data);
      return response.hasAccess;
    } catch (e) {
      if (e.status === 403 && showAlert) {
        this.tempService.setAlertData({message: 'Sorry, your subscription does not include access to this page. Please contact <a href="mailto:healthcare@ipdanalytics.com">healthcare@ipdanalytics.com</a> to request access', redirect});
      }
      return false;
    }
  }

}
