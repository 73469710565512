import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { PlayerComponent } from './components/player/player.component';
import { PodcastListComponent } from './components/podcast-list/podcast-list.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ClipboardModule} from 'ngx-clipboard';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import { FooterComponent } from './footer/footer.component';



@NgModule({
  declarations: [HeaderComponent, PlayerComponent, PodcastListComponent, FooterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    ClipboardModule,
    NgSelectModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponent,
    PlayerComponent,
    PodcastListComponent,
    NgbModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    ClipboardModule,
    NgSelectModule,
    ToastrModule,
    FooterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
