import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Duration } from 'luxon';
import {StreamState} from '../interface/stream-state';
import {error} from '@angular/compiler/src/util';
import {TemporaryService} from './temporary.service';


@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private stop$ = new Subject();
  private audioObj = new Audio();
  audioEvents = [
    'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart', 'volume'
  ];
  private state: StreamState = new StreamState();

  private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);

  constructor(private tempService: TemporaryService) {

  }

  private streamObservable(url, volume, playSpeed) {
    return new Observable(observer => {
      // Play audio
      this.audioObj.src = url;
      this.audioObj.load();
      if (volume){
        this.state.playVolume = volume;
        this.audioObj.volume = (volume / 100);
      }
      if (playSpeed){
        this.audioObj.playbackRate = playSpeed;
      }

      this.audioObj.play().catch(e => {
        this.tempService.setAlertData({message: e});
      });

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };

      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };
    });
  }

  private addEvents(obj, events, handler) {
    events.forEach(event => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj, events, handler) {
    events.forEach(event => {
      obj.removeEventListener(event, handler);
    });
  }

  playStream(url, volume, playSpeed) {
    return this.streamObservable(url, volume, playSpeed).pipe(takeUntil(this.stop$));
  }

  play() {
    this.audioObj.play();
  }

  pause() {
    this.audioObj.pause();
  }

  stop() {
    this.stop$.next();
  }

  seekTo(seconds) {
    this.audioObj.currentTime = seconds;
  }

  formatTime(time: number, format: string = 'hh:mm:ss') {
    return Duration.fromMillis(time * 1000).toFormat(format);
  }

  changeVolume(percent) {
    this.state.playVolume = percent;
    this.audioObj.volume = (percent / 100);
  }

  changePlaySpeed(value) {
    this.audioObj.playbackRate = value;
  }

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case 'canplay':
        this.state.duration = Math.floor(this.audioObj.duration);
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case 'playing':
        this.state.playing = true;
        break;
      case 'pause':
        this.state.playing = false;
        this.state.paused = true;
        break;
      case 'ended':
        this.state.completed = true;
        this.state.completePercent = 100;
        this.state.playing = false;
        this.state.paused = false;
        break;
      case 'timeupdate':
        this.state.currentTime = Math.ceil(this.audioObj.currentTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        this.state.completePercent = (this.state.currentTime) ? (Math.floor((100 / this.state.duration) * this.state.currentTime )) : 0;
        break;
      case 'error':
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = new StreamState();
  }

  getState(): Observable<StreamState> {
    return this.stateChange.asObservable();
  }
}
