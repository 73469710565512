export class Logindata {
  aud: string;
  email: string;
  exp: number;
  // tslint:disable-next-line:variable-name
  family_name: string;
  // tslint:disable-next-line:variable-name
  given_name: string;
  accountId: string;
  subscriberPermission: Array<string> = [];
  therapeuticInsightsArea: Array<string> = [];
  userId: string;
  iss: string;
  jti: string;
  sub: string;
}
