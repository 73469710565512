export class StreamState {
  playing: boolean;
  readableCurrentTime: string;
  readableDuration: string;
  duration: number | undefined = undefined;
  currentTime = 0;
  canplay = false;
  error = false;
  playSpeed = 1;
  playVolume = 100;
  completePercent = 0;
  completed = false;
  paused = false;
}
