import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  async canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const result = await this.authService.checkUser('authguard');
    if (result) {
      if (next.data.roles) {
        let roleExists = false;
        next.data.roles.forEach(role => {
          if (this.authService.currentUserRoles.indexOf(role) !== -1) {
            roleExists = true;
          }
        });
        if (roleExists) {
          return true;
        } else {
          this.router.navigate(['/home']);
          return false;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const result = await this.authService.checkUser('authguard');
    if (result) {
      if (this.authService.currentUserRoles.indexOf('Podcast') === -1) {
        window.location.href = environment.homeUrl;
        return false
      }
      return true;
    } else {
      return false;
    }
  }
}
