import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {PodcastMetadata} from '../model/podcastMetadata.model';

@Injectable({
  providedIn: 'root'
})
export class TemporaryService {

  private tempData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private selectedPodcast: BehaviorSubject<PodcastMetadata> = new BehaviorSubject<PodcastMetadata>(null);
  getSelectedPodcast = this.selectedPodcast.asObservable();

  private selectedPodcastIndex: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  getSelectedPodcastIndex = this.selectedPodcastIndex.asObservable();

  private podcastPlayList: BehaviorSubject<Array<PodcastMetadata>> = new BehaviorSubject<Array<PodcastMetadata>>([]);
  getPodcastPlayList = this.podcastPlayList.asObservable();

  private alertData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  getAlertData = this.alertData.asObservable();

  setTempData(data: any) {
    this.tempData.next(data);
  }

  getTempData() {
    const data = this.tempData.value;
    this.tempData.next(null);
    return data;
  }

  setSelectedPodcast(podcast: PodcastMetadata) {
    this.selectedPodcast.next(podcast);
  }

  setSelectedPodcastIndex(value: number) {
    this.selectedPodcastIndex.next(value);
  }

  setPodcastPlayList(list: Array<PodcastMetadata>) {
    this.podcastPlayList.next(list);
  }

  setAlertData(data: any) {
    this.alertData.next(data);
  }

}
