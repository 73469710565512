<div class="container">
  <app-header></app-header>
  <app-player></app-player>
  <div class="admin-sec">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  <br />
  <app-footer></app-footer>
  <br />
  <ng-template #alertmodal let-modal>
    <div class="confirm-modal">
      <input type="text" ngbAutofocus style="display:none" />
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Alert</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('closed')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-2">
          <span class="text-warning">
            <i class="fas fa-exclamation-triangle fa-3x"></i>
          </span>
          </div>
          <div class="col-sm-10">
            <p class="mt-3" [innerHTML]="alertMessage.message"></p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm float-left" (click)="closeModal('closed')">Ok</button>
      </div>
    </div>
  </ng-template>
</div>
