import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  userData: any;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    authService.getCurrentUser.subscribe(data => {
      this.userData = data;
    });
  }

  async ngOnInit() {
    const token = this.activeRoute.snapshot.queryParamMap.get('token');
    const returnUrl = this.activeRoute.snapshot.queryParamMap.get('returnURL');
    if (token) {
      await this.authService.processToken(token);
      this.router.navigate([returnUrl || '/home']);
    } else {
      if (this.authService.checkUser('authcomponent')) {
        await this.router.navigate([returnUrl || '/']);
      }
    }
  }
}
