import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {throwError} from 'rxjs';

@Injectable()
export class ExtendedHttpClient {

  apiUrl: string = environment.apiEndPoint;

  constructor(
    protected http: HttpClient,
    private authService: AuthService
  ) {
  }

  async get(url: string, options?): Promise<any> {
    url = this.checkurl(url);
    try {
      const response: any = await this.http.get(url, options).toPromise();
      if (response?.newToken) {
        await this.authService.setCurrentToken(response.newToken);
        return response.data;
      }
    } catch (e) {
      throw e;
    }
    return await this.http.get(url, options).toPromise();
  }

  async post(url: string, body: any | null, options?): Promise<any> {
    url = this.checkurl(url);
    try {
      const response: any = await this.http.post(url, body, options).toPromise();
      if (response?.newToken) {
        await this.authService.setCurrentToken(response.newToken);
        return response.data;
      }
    } catch (e) {
      if (e.status !== 401 && e.newToken) {
        await this.authService.setCurrentToken(e.error.newToken);
      }
      throw e;
    }
  }

  async put(url: string, body: any | null, options?): Promise<any> {
    url = this.checkurl(url);
    try {
      const response: any = await this.http.put(url, body, options).toPromise();
      if (response?.newToken) {
        await this.authService.setCurrentToken(response.newToken);
        return response.data;
      }
    } catch (e) {
      throw e;
    }
  }

  async delete(url: string, options?): Promise<any> {
    url = this.checkurl(url);
    try {
      const response: any = await this.http.delete(url, options).toPromise();
      if (response?.newToken) {
        await this.authService.setCurrentToken(response.newToken);
        return response.data;
      }
    } catch (e) {
      throw e;
    }
  }

  checkurl(url: string) {
    const pattern = /((http|https|ftp):\/\/)/;
    if (!pattern.test(url)) {
      return this.apiUrl + url;
    } else {
      return url;
    }
  }

}
