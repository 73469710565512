import { Injectable } from '@angular/core';
import {ModalDismissReasons, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalReference: NgbModalRef;
  modalService: NgbModal;
  private closeResult: string;
  private modaloptions: any = {size: 'lg', centered: true, keyboard: false, backdrop: 'static'};

  constructor(modalService: NgbModal) {
    this.modalService = modalService;
  }

  openModal(content: any, options?: any) {
    if (options) {
      this.modaloptions = {...this.modaloptions, ...options};
    }
    this.modalReference = this.modalService.open(content, this.modaloptions);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      // console.log(this.closeResult);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(this.closeResult);
    });
    return this.modalReference;
  }

  closeModal(reason?: any) {
    this.modalReference.close(reason);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
