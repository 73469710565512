import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {TemporaryService} from '../../../../services/temporary.service';
import {ModalService} from '../../../../services/modal.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {

  @ViewChild('alertmodal') alertmodal: ElementRef;

  modalReference: NgbModalRef;
  alertMessage: any;

  constructor(
    private temporaryService: TemporaryService,
    private modalService: ModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.temporaryService.getAlertData.subscribe(result => {
      if (result) {
        this.alertMessage = result;
        this.modalReference = this.modalService.openModal(this.alertmodal);
      }
    });
  }

  onActivate(event: Event) {
    window.scrollTo(0, 0);
  }

  closeModal(message) {
    this.modalReference.close(message);
    if (this.alertMessage.redirect) {
      this.router.navigate(['/home']);
    }
  }

}
