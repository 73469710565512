import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {retry, catchError, filter, switchMap, take, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiUrl = request.url.startsWith(environment.apiEndPoint);
    if (isApiUrl) {
      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            return this.handleResponseError(error, request, next);
          })
        );
    }
    return next.handle(request);
  }

  handleResponseError(error, request?, next?) {
    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (error.status === 401) {
      return this.handle401Error(request, next, error);
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
    }

    // Server error
    else if (error.status === 500) {
      // Show message
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
    }

    return throwError(error);
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    window.location.href = this.authService.authenticateUrl;
    // console.log(error);
    return throwError(error);
  }
}
